import React, {createContext, useEffect, useState} from "react";
import useActivities from './hooks/activities';
import useFilters from './hooks/filter';
import {useSelector} from "react-redux";
import moment from 'moment'

export const ActivitiesContext = createContext({});

const getDefaultView = (showTable) => {
  const _view = sessionStorage.getItem("aLV");
  const query = new URLSearchParams(window.location.search);
  switch (_view || (query.get("view") || '').toLocaleLowerCase()) {
    case 'table':
      if (showTable)
        return 'table'
      else
        return 'activity'
    case 'calendar':
      return 'calendar'
    default:
      return 'activity'

  }
}

export const Provider = props => {
  const [filters, setFilters] = useFilters();
  const [activities, sort, setSort, fetchActivities, guides, fetchAllGuides, page, rowsPerPage, totalItems, setPage, setRowsPerPage, filter, setFilter, checkBoxes, setCheckBoxes, noGuideTours, submitGuideChange, exportEvents] = useActivities();

  const auth = useSelector(state => state.auth);
  //let _showTable = auth.role === "admin" || auth.role === "superadmin" || auth.role === "security";
  const [showTable, setShowTable] = useState(auth.userData.tour_guide == "1");

  const [view, setView] = useState(getDefaultView(showTable));

  const calendarDate = sessionStorage.getItem('calendarDate');
  const currentDate = calendarDate ? new Date(calendarDate) : new Date();
  const momentDate = moment(currentDate);
  const [calRange, setCalRange] = useState({
    startTime: momentDate.startOf('month').startOf('week').unix(),
    endTime: momentDate.endOf('month').endOf('week').unix()
  })


  const handlePagination = (action) => {
    setFilters('page', (action === 'next' ? filters.page += 1 : filters.page -= 1));
    const page = sessionStorage.getItem("aLP");
    const newPage = (action === 'next' ? Number(page) + 1 : Number(page) - 1);
    sessionStorage.setItem("aLP", newPage);
  };

  useEffect(() => {
    const momentDate = moment(currentDate);
    const newStart = momentDate.clone().startOf('month').startOf('week').unix();
    const newEnd = momentDate.clone().endOf('month').endOf('week').unix();

    if (calRange.startTime !== newStart || calRange.endTime !== newEnd) {
      setCalRange({ startTime: newStart, endTime: newEnd });
    }
  }, [currentDate]); // Removed calRange from the dependencies to prevent a loop

  useEffect(() => {
    if(auth.userData)
      setShowTable(auth.role === "admin" || auth.role === "superadmin" || auth.role === "security" || auth.userData.tour_guide == "1")
  }, [auth]);

  useEffect(() => {
    if (view === "table" && auth.userData) {
      fetchActivities(filters, view, auth.userData.tour_guide == "1");
    }
  }, [view, rowsPerPage, page, sort, checkBoxes, filters, auth.userData])

  useEffect(() => {
    if (view !== "table" && auth.userData) {
      fetchActivities(filters, view, auth.userData.tour_guide == "1");
    }
  }, [filters, view, auth.userData, calRange]);

  const usersContext = {
    filters,

    activities,
    view,
    setFilters,
    sort,
    setSort,

    handlePagination,
    setView,
    guides,
    fetchAllGuides,
    page,
    rowsPerPage,
    totalItems,
    setPage,
    setRowsPerPage,
    filter,
    setFilter,
    fetchActivities,
    checkBoxes,
    setCheckBoxes,
    noGuideTours,
    submitGuideChange,
    calRange,
    setCalRange,
    exportEvents
  };

  return <ActivitiesContext.Provider value={usersContext}>{props.children}</ActivitiesContext.Provider>;
};

export const {Consumer} = ActivitiesContext;
